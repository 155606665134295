import React from "react";
import "./Card.css";

function Card({ img, cta, title, ...props }) {
  const imgStyle = {
    backgroundImage: `url(${img})`,
    backgroundColor: `var(--primary-bg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  return (
    <div className="Card">
      <div className="Card-media" style={imgStyle}></div>
      <div className="Card-body">
        <div className="Card-title">
          <span>{title}</span>
        </div>
        <div className="Card-content">{props.children}</div>

        <div className="Card-cta">{cta}</div>
      </div>
    </div>
  );
}

export default Card;
