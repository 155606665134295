import React from "react";
import {
  Link
} from "react-scroll";

import "./Navigation.css";

function Navigation() {
  return (
    <ul className="Navigation">
      <li>
        <Link
          activeClass="Navigation-active"
          to="experience"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        >
          Experience
        </Link>
      </li>
      <li>
        <Link
          activeClass="Navigation-active"
          to="projects"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        >
          Projects
        </Link>
      </li>
      <li>
        <Link
          activeClass="Navigation-active"
          to="aboutMe"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        >
          About Me
        </Link>
      </li>
      <li>
        <Link
          activeClass="Navigation-active"
          to="contact"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        >
          Contact
        </Link>
      </li>
    </ul>
  );
}

export default Navigation;
