import React from "react";
function Date({ start, end }) {
  return (
    <div className="Date">
        {start} - 
        {" "} {end}
    </div>
  );
}

export default Date;
