import React, {useContext} from "react";
import "./Contact.css";
import { FirebaseContext } from "../Firebase/Firebase";

function Contact({ email, header }) {
  const firebase = useContext(FirebaseContext);

  const onClick = () => {
    firebase.analytics.logEvent('send_email');
  }

  return (
    <div className="Contact">
      {header}
      <div className="Contact-content">
        <h3 className="Contact-tagline">Let's have a chat - Get in touch @</h3>
        <a  target="_blank" rel="noopener noreferrer" onClick={onClick} href="mailto:cernst11@gmail.com">{email}</a>
      </div>
    </div>
  );
}

export default Contact;
