import React from "react";
import "./Intro.css";

function Intro({ children }) {
  return (
    <div className="Intro">
      <div className="intro-about-me">
        <span className="intro-name"> Hi there, I'm Chris </span>
        <span className="intro-tag-line">
          I'm a Fullstack developer living in Amsterdam working on helping
          people experience the world, line by line
        </span>
      </div>
      {children}
    </div>
  );
}

export default Intro;
