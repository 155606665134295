import React from "react";
import Card from "../Card/Card";
import "./Projects.css";

function Projects({ projects, header }) {
  return (
    <div className="Projects">
      <div className="Projects-header">{header}</div>
      <div className="Projects-list">
     
        {projects.map((project, index) => (
          <div key={index} className="Projects-entry">
            <Card
              title={project.title}
              img={process.env.PUBLIC_URL + project.img}
              cta={<a  rel="noopener noreferrer" target="_blank" href={project.link}>CHECK IT OUT</a>}
            >
              <p>{project.description}</p>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
