import React from "react";
import "./App.css";
import Contact from "../Contact/Contact";
import { Profile } from "../../data.js";
import Timeline from "../Timeline/Timeline";
import Intro from "../Intro/Intro";
import Projects from "../Projects/Projects";
import Navigation from "../Navigation/Navigation";
import Shibe from "../Shibe/Shibe";
import AboutMe from "../AboutMe/AboutMe";

const App = () => {
 
  return (
    <div className="App">
      <div className="navigation">
        <Navigation />
      </div>
      <div className="content">
        <div id="intro" className="intro-content">
          <Intro>
            <Shibe></Shibe>
          </Intro>
        </div>
        <div id="experience" className="experience-content">
          <Timeline
            jobs={Profile.jobs}
            sectionTitle={
              <h2 style={{ paddingLeft: "10px" }} className="section-header">
                Experience
              </h2>
            }
          />
        </div>
        <div id="projects" className="project-content">
          <Projects
            header={<h2 className="section-header">Personal Projects</h2>}
            projects={Profile.projects}
          />
        </div>
        <div id="aboutMe" className="aboutme-section">
          <AboutMe
            about={Profile.about_me}
            img={Profile.avatar.img}
            header={<h2 className="section-header">About Me</h2>}
          />
        </div>
        <div id="contact" className="contact-section">
          <Contact
            email={Profile.avatar.email}
            header={<h2 className="section-header">Contact</h2>}
          ></Contact>
        </div>
      </div>
    </div>
  );
};
export default App;
