import React from "react";
import "./Shibe.css";

function Shibe() {
  const style = {};

  return (
    <div className="Doge">
      <div className="Doge-img-container">
        <img
          className="Doge-img"
          alt="hello shibe"
          style={style}
          src={`${process.env.PUBLIC_URL}/img/shibe.png`}
          loading={"lazy"}
        />
      </div>
    </div>
  );
}

export default Shibe;
