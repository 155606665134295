import * as firebase from "firebase/app";
import "firebase/analytics";

const config = {
  apiKey: "AIzaSyCFG3QgVs_RMi5qs6srn8rNLmdankwc_x4",
  authDomain: "about-me-b0293.firebaseapp.com",
  databaseURL: "https://about-me-b0293.firebaseio.com",
  projectId: "about-me-b0293",
  storageBucket: "about-me-b0293.appspot.com",
  messagingSenderId: "382203022855",
  appId: "1:382203022855:web:ad0e2c1e0732a1f11724a2",
  measurementId: "G-T8KC4BTGPG",
};

function Firebase() {
  firebase.initializeApp(config);
 
  this.firebase = firebase;
  this.analytics = firebase.analytics();
}

export default Firebase;
