import React, { useState, useContext } from "react";
import Job from "../Job/Job";
import "./Timeline.css";
import { FirebaseContext } from "../Firebase/Firebase";

function Timeline({ jobs, sectionTitle }) {
  const [openSection, setOpenSection] = useState();
  const firebase = useContext(FirebaseContext);

  const onClick = (id, company) => {
    openSection === id ? setOpenSection() : setOpenSection(id);
    firebase.analytics.logEvent("viewed_job", { company });
  };

  return (
    <>
      {sectionTitle}
      <div className="Timeline ">
        {jobs.map((job, index) => (
          <div key={job.id.toString()}>
            <TimelineEntry img={job.img} alt={job.alt}>
              <Job
                job={job}
                isOpen={job.id === openSection}
                onClick={onClick}
              />
            </TimelineEntry>
          </div>
        ))}
      </div>
    </>
  );
}

function TimelineEntry({ img, alt, children }) {
  const timelineImgPlaceHolder = {
    position: "absolute",
    width: "50px",
    height: "50px",
    right: "-17px",
    background: "white",
    top: "5px",
    borderRadius: "50%",
    zIndex: "2",
    left: "6px",
  };

  return (
    <div className="Timeline-container right">
      <img
        alt={alt}
        loading={"lazy"}
        style={timelineImgPlaceHolder}
        src={img}
      />
      <div className="Timeline-content">{children}</div>
    </div>
  );
}

export default Timeline;
