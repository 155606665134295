export const Profile = {
  jobs: [
    {
      id: 1,
      company: "Booking.com",
      postition: "Senior Software Developer",
      start: "September 2021",
      end: "Current",
      location: "Amsterdam, NL 🇳🇱",
      responsibilities: [
        "Build and support federated GraphQL gateway",
        "Support downstream teams in adding GraphQL endpoints into their service in order to be fully federated",
        "Create internal tooling for GraphQL",
        "Create trainings and docs for best practices for GraphQL",
      ],
      img: "../img/booking.png",
      backGroundColor: "0000FF",
      alt: "Booking.com icon",
    },
    {
      id: 2,
      company: "Booking.com",
      postition: "Fullstack Software Developer",
      start: "May 2018",
      end: "August 2021",
      location: "Amsterdam, NL 🇳🇱",
      responsibilities: [
        "Created new hostel landing pages that provided more relevant travel information to hostel bookers. This involved engineering a wide range of front end components and Perl and Java services.",
        "Created and gave internal trainings for React and Chrome dev tools for beginners and advanced users.",
        "Created a Java service for our partners to be able to specify events happening at their property and deployed the service to kubernetes.",
        "Created internal community project for frontend engineering that led to the creation of a new team dedicated to creating the React framework of the frontend replatform project.",
        "Designed and built custom dashboards for my team which used mapbox api, grafana and leveraged hadoop to generate the data.",
        "Expanded on internal hotel search engine by adding new filters that were more relevant to our target audience.",
        "Built in house libraries for image lazy loading and generating Google Analytics events when a taggable part of the screen became in view.",
      ],
      img: "../img/booking.png",
      backGroundColor: "0000FF",
      alt: "Booking.com icon",
    },
    {
      id: 3,
      company: "Cabelas",
      postition: "Fullstack Software Developer",
      start: "May 2015",
      end: "April 2018",
      location: "Omaha, NE 🌽",
      responsibilities: [
        "Created the ability for Cabelas.com to show dynamic prices based on customer type. This allowed business customers and employees to use the website and have targeted item pricing displayed to them.",
        "Developed in conjunction with retail business partners a process which allows customers to order firearms online for in-store pickup, which generated over 10% increase in weekly online revenue.",
        "Worked with the development team to create a process to allow Cabelas.com to accept orders when credit card authorization and tokenization processor is unavailable and provide analytics into why these issues occurred.",
        "Coordinated with a large team to create a new method to process and track firearms in accordance with ATF regulations using Spring Boot and Angular. This reduced time for a customer to finalize the purchase of a firearm from 8 minutes to less than a minute.",
        "Developed a web based tool to analyze product attributes allowing business users to find configuration issues with products which prevented them from being sold online.",
        "Created monitoring process to detect issues and alert service desk in linux batch processing jobs.",
      ],
      img: "../img/cabelas.png",
      backGroundColor: "FF00FF",
      alt: "Cabelas icon",
    },
    {
      id: 4,
      company: "Kidwell",
      postition: "Project Mangement Intern",
      start: "September 2014",
      end: "May 2014",
      location: "Lincoln, NE 🌽",
      responsibilities: [
        "Managed client projects and coordinated with technicians to find solutions which best meet their business needs.",
        "Audited customers internal computers and network ensuring they are running optimally and developed system to automatically send audit reports to clients reducing audit time by 50% for the service desk.",
        "Developed system to monitor real time service desk call statistics and display on operations service board.",
      ],
      img: "../img/kidwell.png",
      backGroundColor: "FF00FF",
      alt: "Kidwell icon",
    },
    {
      id: 5,
      company: "BitStore GMBH",
      postition: "IT intern",
      start: "May 2014",
      end: "August 2014",
      location: "Berlin, DE 🌭",
      responsibilities: [
        "Converted windows server systems from physical to virtual machines for clients.",
        "Repaired and upgraded customers computers on-site.",
        "Refreshed and maintained laptop rentals to ensure optimal performance during their use.",
      ],
      img: "../img/bitstore.png",
      backGroundColor: "FF00FF",
      alt: "BitStore icon",
    },
  ],

  avatar: {
    name: "Christian Ernst",
    img: "../img/AboutMe2.gif",
    email: "cernst11@gmail.com",
    phoneNumbers: [
      { region: "US", number: "1-402-739-9237" },
      { region: "EU", number: "+31 616 236 195" },
    ],
    location: "Amsterdam, Netherlands",
    github: "https://github.com/cernst11/",
  },

  about_me:
    "Hey there, my name is Chris Ernst, living in Amsterdam for over 2 years.\n\n I have over 5 years experience working on the entire web development stack. I leverage Java, JavaScript, Perl and even the occasional bash script to build robust services and tools to improve functionality on websites that have a global audience.\n\n I am passionate about discovering the world, meeting and working with as many people from as many backgrounds as possible. I am always eager to learn more about any subject which is why I like to keep an eye out for new advances.",
  education: {},

  projects: [
    {
      title: "Karte",
      link: "https://github.com/cernst11/karte",
      techStack: [
        "js",
        "webpack",
        "custom binding framework",
        "mapBox",
        "html canvas",
      ],
      description:
        "Karte allows anyone to generate a customizable print quality map of their faviorite places around the world.",
      img: `../img/map.jpg`,
    },
    {
      title: "Solais Web",
      link: "https://github.com/cernst11/solais-web",
      techStack: ["ReactJS", "HTML canavs"],
      description:
        "Create a lighting color scene based on your faviorite image",
      img: "../img/solais_web.jpg",
    },

    {
      title: "Solais Android",
      techStack: ["android", "java", "lifx api"],
      img: "../img/solais_music.jpg",
      description:
        "Create a ligting scene based on your phones currently playing music album art",
      link: "https://play.google.com/store/apps/details?id=com.chickenvision.ernst.ealainsolais&hl=en_AU",
    },

    {
      title: "Luminous",
      img: "../img/luminous.jpg",
      techStack: ["Polymer HTML", "NodeJS", "ws2812 LED"],
      description:
        "A powerful RGB LED light strip effects engine to create vibrant and customizable animations ",
      link: "https://github.com/cernst11/luminous-controller",
    },
  ],
};
