import React from "react";
import Date from "../Date/Date";
import "./Job.css";

function Job({ job, isOpen, onClick }) {
  return (
    <div className="Job" onClick={() => onClick(job.id, job.company)}>
      <div className="Job-data">
        <span className="Job-header">{job.postition}</span>
        <div className="Job-biline">
          <span className="Job-company">{job.company}</span> -{" "}
          <span className="Job-location">{job.location}</span>
        </div>
        <span className="Job-date">
          <Date start={job.start} end={job.end} />
        </span>

        <button className="open-modal">TELL ME MORE</button>

        <ul
          className="responsibilities"
          style={{ display: isOpen ? "block" : "none" }}
        >
          {job.responsibilities.map((responsibility, index) => (
            <li className="responsibility" key={index}>
              {responsibility}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Job;
