import React from "react";
import "./AboutMe.css";
function AboutMe({ about, header, img }) {
  return (
    <div className="AboutMe">
      {header}
      <div className="AboutMeContent">
        <div className="AboutMeTextCont">
          <p className="AboutMeText">{about}</p>
        </div>
        <div className="AboutMeImgCont">
          <img
            className="AboutMeImg"
            alt="Image of me"
            src={img}
            loading={"lazy"}
          />
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
